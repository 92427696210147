const { INTERNAL, BROWSE_COMPLETED_VIDEOS_INTERNAL } =
  require('./requiredPermissions').default;

export const topNavLinks = [
  {
    label: 'Manage Clients',
    url: '/internal/organisations',
    icon: 'web',
    requiredPermission: INTERNAL
  },
  {
    label: 'Manage Traffic',
    url: '/traffic',
    icon: 'dashboard',
    requiredPermission: INTERNAL
  },
  {
    label: 'Browse Videos',
    url: '/internal/all-projects',
    icon: 'video',
    requiredPermission: BROWSE_COMPLETED_VIDEOS_INTERNAL
  },
  {
    label: 'Reports',
    url: '/internal/reporting',
    icon: 'mediareport',
    requiredPermission: INTERNAL
  },
  {
    label: 'Project Reports',
    url: '/internal/am-dashboard',
    icon: 'datareport',
    requiredPermission: INTERNAL
  },
  {
    label: 'System Library',
    url: '/system-asset-library/pro',
    icon: 'camera',
    requiredPermission: INTERNAL
  }
];

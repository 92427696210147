// @flow

import React from 'react';
import { RequestProvider } from '@shootsta/common-react';
import { withRouter } from 'react-router-dom';
import type { RouterHistory } from 'react-router-dom';
import LoginComponent from '../components/Login';
import { forgotPasswordApi, getOrgNameFromAliasApi } from '../api';
import { loginApi } from '../../../api';

const Login = ({ history }: { history: RouterHistory }) => (
  <RequestProvider
    requests={{
      login: loginApi(),
      forgotPassword: forgotPasswordApi(),
      getOrganisationNameFromAlias: getOrgNameFromAliasApi()
    }}
  >
    {({ login, forgotPassword, getOrganisationNameFromAlias }) => (
      <LoginComponent
        history={history}
        loginRequest={login}
        forgotPassword={forgotPassword}
        getOrganisationNameFromAlias={getOrganisationNameFromAlias}
      />
    )}
  </RequestProvider>
);

export default withRouter(Login);

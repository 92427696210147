import memoizeOne from 'memoize-one';
import moment from 'moment';
import getTimeColor from './getTimeColor';

function dueDateDiffFrom(onHoldDate: string) {
  if (onHoldDate) {
    const momentOnHoldDate = moment(onHoldDate);
    return momentOnHoldDate.isValid()
      ? momentOnHoldDate
      : moment(parseInt(onHoldDate, 10));
  }

  return moment();
}

export default memoizeOne(({ dueDate, onHoldDate }) => {
  if (!dueDate) {
    return {};
  }

  const momentDueDate = moment(dueDate);
  const parsedDueDate = momentDueDate.isValid()
    ? momentDueDate
    : moment(parseInt(dueDate, 10));
  const hoursLeft = parsedDueDate.diff(dueDateDiffFrom(onHoldDate), 'hours');
  const sign = hoursLeft < 0 ? '-' : '';
  const days = Math.floor(Math.abs(hoursLeft) / 24);
  const hours = Math.abs(hoursLeft % 24);

  return {
    hoursLeft: `${sign}${days ? `${days}d ` : ''}${hours}h`,
    color: getTimeColor(hoursLeft)
  };
});

export default (hoursLeft: number) => {
  if (hoursLeft > 4) {
    return { success: true };
  }

  if (hoursLeft > 2) {
    return { warning: true };
  }

  return { danger: true };
};

import React, { useMemo } from 'react';
import {
  AssetPreview,
  Checkbox,
  List,
  RadioButton,
  Text
} from '@shootsta/common-react';
import '../styles/_additional-field-carousel.scss';

type CarouselListComponentProps = {
  handleSelect: Function,
  field: any,
  type: string,
  disabled: boolean,
  assetsMap: Map<string, Object>
};

function CarouselListComponent({
  field,
  type,
  disabled,
  handleSelect,
  assetsMap
}: CarouselListComponentProps) {
  const { fieldId, label, assetId, isSelected } = field;

  const renderSelector = () => {
    switch (type) {
      case 'CHECKBOX':
        return (
          <Checkbox
            checked={isSelected}
            onChange={() => handleSelect(fieldId)}
            disabled={disabled}
          />
        );
      default:
        return (
          <RadioButton
            selected={isSelected}
            onChange={() => handleSelect(fieldId)}
            disabled={disabled}
          />
        );
    }
  };
  const assetTitle = useMemo(() => {
    const asset = assetsMap.get(assetId);
    const { title } = asset || {};
    return title || 'Untitled';
  }, [assetsMap]);

  return (
    <List.CardItem
      key={fieldId}
      onClick={() => handleSelect(fieldId)}
      customCoverImage={
        <AssetPreview
          assetId={assetId}
          height={80}
          width={80}
          hideFileTypeIcon
          canPlayVideo
          canPlayAudio
        />
      }
      smallCoverImage
    >
      <div className="additional-field__field__checkbox">
        {renderSelector()}
      </div>
      <Text heading5 noMargin ellipsisOverflow>
        {label || assetTitle}
      </Text>
    </List.CardItem>
  );
}

export default CarouselListComponent;

export default function onEnterPressed({ onClick }: { onClick?: Function }) {
  if (!onClick) {
    return null;
  }

  return (event: SyntheticKeyboardEvent<*>) => {
    // Keycode is deprecated in newer browsers but some old ones still use it
    if (event.which === 13 || event.keyCode === 13) {
      event.preventDefault();
      event.stopPropagation();

      onClick(event);
    }
  };
}

import { credentials } from '@shootsta/client-auth';
import type { RouterHistory as History } from 'react-router-dom';
import {
  clearSSOLocalStore,
  getIdentityOriginUrl,
  getSSOLocalStore,
  redirectToSingleLogout
} from '../../utils';
import { SSO_LOGIN_CREDENTIALS_FLAG } from '../constants';

type HandleSignOutArgs = {
  history: History,
  loginViaIdentity: boolean,
  isGhosting?: boolean,
  redirectPath?: string
};

export default ({
  history,
  loginViaIdentity,
  isGhosting,
  redirectPath
}: HandleSignOutArgs) => {
  const { ssoEnabled, sloDisabled } = getSSOLocalStore();

  const existingCredentials = credentials.get();
  const { [SSO_LOGIN_CREDENTIALS_FLAG]: ssoLogin } = existingCredentials || {};

  clearSSOLocalStore();

  // If ghosting or not ssoEnabled, show HUB login page
  if (!ssoEnabled || isGhosting || !ssoLogin) {
    if (redirectPath) {
      history.push(redirectPath);
    } else {
      history.push('');
    }

    return !loginViaIdentity ? credentials.clear() : history.push('/logout');
  }

  // If ssoEnabled and sloDisabled, redirect to Identity logout
  if (sloDisabled) {
    credentials.clear();
    return (window.location.href = getIdentityOriginUrl());
  }

  const location = redirectPath ? { pathname: redirectPath } : {};

  history.push({ ...location, search: '?redirecting=true' });
  credentials.clear();

  return redirectToSingleLogout();
};

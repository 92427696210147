import React, { type Node } from 'react';
import { Button, Text } from '@shootsta/common-react';

type SetupWizardProps = {
  buttonText?: string,
  buttonDisabled?: boolean,
  onClick: Function,
  goToNextWizardStage?: Function,
  children: Node,
  onSkipForNow: Function
};

function SetupWizardChild({
  buttonText,
  buttonDisabled,
  onClick,
  goToNextWizardStage,
  children,
  onSkipForNow
}: SetupWizardProps) {
  return (
    <div className="wizard-body">
      {children}
      <Button
        isFullWidth
        onClick={() => onClick(goToNextWizardStage)}
        disabled={buttonDisabled}
      >
        {buttonText}
      </Button>
      <div className="wizard-body__skip">
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            onSkipForNow?.();
          }}
        >
          <Text noMargin>Skip for now</Text>
        </a>
      </div>
    </div>
  );
}

export default SetupWizardChild;

function importRetry(
  fn: Function,
  //$FlowFixMe
  retries = 5,
  //$FlowFixMe
  interval = 1000
): Promise<Function> {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retries === 0) {
            reject(error);
            return;
          }
          importRetry(fn, retries - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
}

export default importRetry;

const VIDYARD_ALIAS = 'vidyard';
const SHOOTSTA_ALIAS = 'shootsta';
const RECRUITMENT_ALIAS = 'recruitment';
const ELEVATETESTING_ALIAS = 'elevatetesting';
const ELEVATEDEMOS_ALIAS = 'elevatedemos';
const ASIAMARKETENTRY_ALIAS = 'asiamarketentry';
const UNDERCOVERINC_ALIAS = 'undercoverinc';
const SHOOTSTAEVENTS_ALIAS = 'shootstaevents';
const SHOOTSTAMARKETING_ALIAS = 'shootstamarketing';
const SHOOTSTA_DEMO = 'demo';
const SHOOTSTA_CSM = 'shootstacsm';

export {
  VIDYARD_ALIAS,
  SHOOTSTA_ALIAS,
  RECRUITMENT_ALIAS,
  ELEVATETESTING_ALIAS,
  ELEVATEDEMOS_ALIAS,
  ASIAMARKETENTRY_ALIAS,
  UNDERCOVERINC_ALIAS,
  SHOOTSTAEVENTS_ALIAS,
  SHOOTSTAMARKETING_ALIAS,
  SHOOTSTA_DEMO,
  SHOOTSTA_CSM
};

import fingerprintJs from '@fingerprintjs/fingerprintjs';

export const FINGERPRINT_KEY = 'fingerprint';

export default async function getFingerprint(): Promise<string> {
  const cachedFingerprint = localStorage.getItem('fingerprint');

  if (cachedFingerprint) {
    return cachedFingerprint;
  }

  const fp = await fingerprintJs.load();
  const fingerprint = (await fp.get()).visitorId;

  localStorage.setItem(FINGERPRINT_KEY, fingerprint);

  return fingerprint;
}

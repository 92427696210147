import React from 'react';
import { Text, ElevatedContainer, Button } from '@shootsta/common-react';
import './_product-banner-large.scss';

type ProductBannerLargeProps = {
  heading?: string,
  ctaText?: string,
  bannerImage?: string,
  ctaClick: Function
};

function ProductBannerLarge({
  heading,
  ctaText,
  bannerImage,
  ctaClick
}: ProductBannerLargeProps) {
  return (
    <div onClick={() => ctaClick()}>
      <ElevatedContainer rounded className="product-banner-large">
        <div className="product-banner-large__banner-content">
          <Text className="product-banner-large__banner-content__header-title">
            {heading}
          </Text>
          {ctaText && (
            <Button className="product-banner-large__banner-content__cta-default">
              {ctaText}
            </Button>
          )}
        </div>
        {bannerImage && (
          <div
            className="product-banner-large__banner-background-image"
            style={{ backgroundImage: `url(${bannerImage})` }}
          />
        )}
      </ElevatedContainer>
    </div>
  );
}

export default ProductBannerLarge;

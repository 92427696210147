import React from 'react';
import { RequestProvider } from '@shootsta/common-react';
import { getRefreshTokenReq, refreshToken } from '@shootsta/client-auth';
import AuthProtectedComponent from '../components/AuthProtected';
import { loginApi } from '../../../api';
import { forgotPasswordApi } from '../../../components/Login/api';

function AuthProtected(props: Object) {
  return (
    <RequestProvider
      requests={{
        login: loginApi(),
        refreshTokenReq: getRefreshTokenReq(),
        forgotPassword: forgotPasswordApi()
      }}
    >
      {({ login, refreshTokenReq, forgotPassword }) => (
        <AuthProtectedComponent
          login={login}
          refreshToken={() => refreshToken(refreshTokenReq)}
          forgotPassword={forgotPassword}
          {...props}
        />
      )}
    </RequestProvider>
  );
}

export default AuthProtected;

import React from 'react';
import { Button, Input, Link } from '@shootsta/common-react';
import type { RouterHistory } from 'react-router-dom';

type Props = {
  passwordValue: string,
  onUpdateField: Function,
  onSubmit: Function,
  onCancel: Function,
  onForgotPassword: Function,
  submitting: ?boolean,
  history: RouterHistory,
  password: string
};

export default function Form({
  passwordValue,
  onUpdateField,
  onSubmit,
  submitting,
  onCancel,
  onForgotPassword,
  history,
  password
}: Props) {
  const goToForgotPassword = () => {
    onCancel();
    history.push('/login/forgot');
  };

  return (
    <>
      <p>{"We need to confirm that it's you"}</p>
      <form>
        <Input
          className="reauthenticate__form__input"
          placeholder="Password"
          value={passwordValue}
          type="password"
          onChange={onUpdateField('password')}
          prefix="padlock"
          label="Password"
          autoComplete="current-password"
          suffixIcon={
            !password ? (
              <Link
                className="login-form__body__forgot-btn"
                onClick={goToForgotPassword}
              >
                FORGOT?
              </Link>
            ) : null
          }
        />
      </form>
      <Button
        className="reauthenticate__form__submit"
        disabled={!passwordValue}
        onClick={onSubmit}
        busy={submitting}
      >
        Login
      </Button>
      <Link button onClick={onForgotPassword}>
        Forgot Password?
      </Link>
      <Link button onClick={onCancel}>
        Not you?
      </Link>
    </>
  );
}

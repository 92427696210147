const {
  SHOOTSTA_ALIAS,
  RECRUITMENT_ALIAS,
  ELEVATETESTING_ALIAS,
  ELEVATEDEMOS_ALIAS,
  UNDERCOVERINC_ALIAS,
  SHOOTSTAEVENTS_ALIAS,
  SHOOTSTAMARKETING_ALIAS,
  SHOOTSTA_DEMO,
  SHOOTSTA_CSM
} = require('./org-aliases');

const SHOOTSTA_PREVIEW_WORKSPACES = [
  SHOOTSTA_ALIAS,
  SHOOTSTA_DEMO,
  RECRUITMENT_ALIAS,
  SHOOTSTAEVENTS_ALIAS,
  SHOOTSTAMARKETING_ALIAS,
  SHOOTSTA_CSM,
  ELEVATETESTING_ALIAS,
  ELEVATEDEMOS_ALIAS,
  UNDERCOVERINC_ALIAS
];

export { SHOOTSTA_PREVIEW_WORKSPACES };

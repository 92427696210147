export default {
  SUBMIT_VIDEO: 'SUBMIT_VIDEO',
  REQUEST_VIDEO_APPROVAL: 'REQUEST_VIDEO_APPROVAL',
  SUBMIT_CHANGES_TO_EDITOR: 'SUBMIT_CHANGES_TO_EDITOR',
  VIDEO_CHANGES_REQUESTED: 'VIDEO_CHANGES_REQUESTED',
  PROJECT_CUSTOM_DUE_DATE: 'PROJECT_CUSTOM_DUE_DATE',
  VIDEO_EDITOR_ASSIGNED: 'VIDEO_EDITOR_ASSIGNED',
  PROJECT_OWNER_CHANGED: 'PROJECT_OWNER_CHANGED',
  COMMENT_MENTION: 'COMMENT_MENTION',
  VIDEO_APPROVED: 'VIDEO_APPROVED',
  PROJECT_BACK_TO_DRAFT: 'PROJECT_BACK_TO_DRAFT',
  RUSH_EDIT_CANCELLED: 'RUSH_EDIT_CANCELLED',
  ORG_PACKAGE_CHANGED: 'ORG_PACKAGE_CHANGED',
  NEW_RESOURCE_COMMENT: 'NEW_RESOURCE_COMMENT',
  ORG_NO_CREDITS_CLIENT: 'ORG_NO_CREDITS_CLIENT',
  ORG_NO_CREDITS_AM: 'ORG_NO_CREDITS_AM',
  ORG_CREDITS_CHANGED: 'ORG_CREDITS_CHANGED',
  PROJECT_CREDITS_ADJUSTED: 'PROJECT_CREDITS_ADJUSTED',
  ADD_MORE_ASSETS: 'ADD_MORE_ASSETS',
  PROJECT_APPROVED_FOR_CAPTIONING: 'PROJECT_APPROVED_FOR_CAPTIONING',
  APPROVE_STORYBOARD: 'APPROVE_STORYBOARD',
  STORYBOARD_REJECTED: 'STORYBOARD_REJECTED',
  REQUEST_SIGN_OFF: 'REQUEST_SIGN_OFF',
  REQUEST_ASSISTANCE: 'REQUEST_ASSISTANCE',
  REJECT_SIGN_OFF: 'REJECT_SIGN_OFF',
  APPROVED_SIGN_OFF: 'APPROVED_SIGN_OFF'
};

export const ROUTE = {
  PUBLIC_UPLOAD: '/pro/public-upload/',
  PRO_COMPLETE: '/pro/complete/',
  CAST_COMPLETE: '/cast/complete/'
};

export const PUBLIC_ROUTES = [
  ROUTE.PUBLIC_UPLOAD,
  ROUTE.PRO_COMPLETE,
  ROUTE.CAST_COMPLETE
];

import React, { Component } from 'react';
import { credentials } from '@shootsta/client-auth';
import { Switch, Text, FLATTENED_COLOURS } from '@shootsta/common-react';
import _debounce from 'lodash.debounce';
import '../styles/_user-availability.scss';

type UserAvailabilityProps = {
  getUserTeam: Function,
  updateUser: Function,
  isAvailable: boolean,
  userId: string
};

type UserAvailabilityState = {
  teamUsers: Array<Object>,
  isAvailable: boolean
};

export default class UserAvailability extends Component<
  UserAvailabilityProps,
  UserAvailabilityState
> {
  constructor(props: UserAvailabilityProps) {
    super();

    this.state = {
      isAvailable: props.isAvailable,
      teamUsers: []
    };
  }

  componentDidMount = () => {
    this.fetchTeam();
  };

  setAvailability = _debounce(async (isAvailable: boolean) => {
    const { updateUser } = this.props;
    const { user, ...rest } = credentials.get();
    const { error, data } = await updateUser({ isAvailable });

    if (error || !data.updateUser) {
      return;
    }

    credentials.set({
      ...rest,
      user: {
        ...user,
        isAvailable
      }
    });
  }, 500);

  fetchTeam = async () => {
    const { getUserTeam } = this.props;

    const { data, error } = await getUserTeam();

    if (error || !data.getUserTeam) {
      return;
    }

    const { members } = data.getUserTeam;

    this.setState({
      teamUsers: members.map(({ user }) => user)
    });
  };

  toggleAvailability = (isAvailable: boolean) => {
    this.setState({ isAvailable });

    this.setAvailability(isAvailable);
  };

  render() {
    const { userId } = this.props;
    const { teamUsers, isAvailable } = this.state;

    const availableTeamUsersCount = teamUsers
      .map((user) => (user.userId === userId ? { ...user, isAvailable } : user))
      .filter((user) => user.isAvailable).length;

    const availableEditorsPercentage = teamUsers.length
      ? (availableTeamUsersCount / teamUsers.length) * 100
      : 0;

    const editorsProgressColor =
      availableTeamUsersCount === teamUsers.length
        ? FLATTENED_COLOURS.monkey
        : FLATTENED_COLOURS.goldenTainoi;

    return (
      <div className="user-availability">
        <hr />
        <div className="user-availability__availability">
          <Text label>Availability</Text>
          <Switch checked={isAvailable} onChange={this.toggleAvailability} />
        </div>
        <div className="user-availability__line">
          <div
            className="user-availability__line--progress"
            style={{
              backgroundColor: editorsProgressColor,
              width: `${availableEditorsPercentage}%`
            }}
          />
        </div>
        <div className="user-availability__editors">
          <Text
            label
            noMargin
          >{`${availableTeamUsersCount}/${teamUsers.length}`}</Text>
          <Text label noMargin>
            Editors available
          </Text>
        </div>
        <hr />
      </div>
    );
  }
}

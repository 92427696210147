import { gql } from 'apollo-boost';

export const getUnreadCountQuery = gql`
  query GetUnreadCount {
    getUnreadCount
  }
`;

export default () => ({
  query: getUnreadCountQuery,
  module: 'notifications',
  ignoreCache: true,
  buildVariables: () => ({})
});

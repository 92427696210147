import React from 'react';
import { Icon, Text } from '@shootsta/common-react';
import './_quick-actions.scss';

function QuickActionCard({ link, iconBgClass, iconName, title, subtitle }) {
  return (
    <div className="quick-actions-card">
      <a href={link} className="quick-actions__link">
        <div className={`quick-actions-card__icon ${iconBgClass}`}>
          <Icon
            className={`quick-actions-card__icon-image dark-${iconBgClass}`}
            size="22"
            name={iconName}
          />
        </div>
        <div className="quick-actions-card__info">
          <Text heading5 noMargin>
            {title}
          </Text>
          <Text noMargin className="quick-actions__subtitle">
            {subtitle}
          </Text>
        </div>
      </a>
    </div>
  );
}

const quickActions = [
  {
    link: '/pro/builder/new',
    iconBgClass: 'purple',
    iconName: 'prodef',
    title: 'Create a Pro project',
    subtitle: 'Write a brief and leave the rest to us'
  },
  {
    link: '/elevate',
    iconBgClass: 'yellow',
    iconName: 'elevatedef',
    title: 'Create an Elevate video',
    subtitle: 'Create a branded video in minutes'
  },
  {
    link: '/cast',
    iconBgClass: 'blue',
    iconName: 'castdef',
    title: 'Record a Cast',
    subtitle: 'Record your screen, annotate and share'
  }
];

export default function QuickActions() {
  return (
    <div className="quick-actions">
      {quickActions.map((action) => (
        <QuickActionCard key={action.iconName} {...action} />
      ))}
    </div>
  );
}

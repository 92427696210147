import { gql } from 'apollo-boost';

const getUserQuery = gql`
  query GetUser($userId: String!) {
    getUser(userId: $userId) {
      userId
      firstName
      lastName
      email
      imageUrl
    }
  }
`;

const getUser = () => ({
  query: getUserQuery,
  module: 'users',
  ignoreCache: true,
  buildVariables: ({ userId }: { userId: string }) => ({ userId })
});

export default getUser;

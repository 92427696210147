import defaultConfig from './default';

type Config = {
  apiEndpoint: string,
  wsEndpoint: string,
  ssoApiEndpoint?: string,
  serviceEndpoint: string,
  thumbnailsEndpoint: string,
  identityEndpoint: string,
  featureFlagServiceClientId: string,
  subscriptionsEndpoint: string,
  shootstaDomainSegments: string,
  skipNotifications?: string,
  DOWNLOAD_MAC_APP_LINK?: string,
  socketEndpoint?: string,
  HIDE_FEATURES: {
    cast: boolean,
    bookings: boolean,
    userHomepage: boolean,
    elevate: boolean,
    LOGIN_VIA_IDENTITY: boolean,
    stockAssets: boolean,
    hubspot: boolean,
    shareElevateVidyard: boolean,
    CREATE_ORGANISATION_FROM_SF: boolean,
    elevateLandingPage: boolean,
    manageElevateTemplates: boolean,
    clientFacingReports: boolean,
    elevateDesktopRecording: boolean,
    manageWorkspaceElevateTemplates: boolean, // Workspace admin manage elevate templates
    manageWorkspaceSplashPages: boolean, // Workspace admin manage splash templates
    manageElevateTags: boolean,
    insightsLink: boolean,
    rightDrawerInsights: boolean,
    racetrack: boolean,
    showElevateHyperBatch: boolean,
    workspaceInsights: boolean,
    hideStoryboardInClientNav: boolean,
    systemAssetLibrary: boolean
  }
};

const config: Config = {
  ...defaultConfig,
  HIDE_FEATURES: {
    cast: false,
    bookings: false,
    userHomepage: false,
    stockAssets: false,
    elevate: false,
    allVideos: false,
    LOGIN_VIA_IDENTITY: false,
    hubspot: false,
    shareElevateVidyard: true,
    CREATE_ORGANISATION_FROM_SF: true,
    elevateLandingPage: false,
    manageElevateTemplates: false,
    captionAcceptance: true,
    animationAcceptance: true,
    clientFacingReports: false,
    elevateDesktopRecording: false,
    manageWorkspaceElevateTemplates: false,
    manageWorkspaceSplashPages: false,
    manageElevateTags: true,
    insightsLink: false,
    rightDrawerInsights: false,
    racetrack: true,
    showElevateHyperBatch: true,
    workspaceInsights: false,
    hideStoryboardInClientNav: true,
    systemAssetLibrary: false
  }
};

export default config;

import _get from 'lodash.get';
import { NOTIFICATION_TYPES } from '../common/constants';

type Variables = {
  notificationType: string,
  fullName?: string,
  resource?: Object
};

const buildNotificationString = ({
  notificationType,
  fullName = '',
  resource
}: Variables) => {
  const resourceName = resource ? resource.name : 'the project';
  const resourceId = resource ? resource.videoId : '';

  switch (notificationType) {
    case NOTIFICATION_TYPES.SUBMIT_VIDEO:
      return `${fullName} submitted a new video.`;
    case NOTIFICATION_TYPES.REQUEST_VIDEO_APPROVAL:
      return `${fullName} has requested video approval.`;
    case NOTIFICATION_TYPES.SUBMIT_CHANGES_TO_EDITOR:
      return `${fullName} submitted changes.`;
    case NOTIFICATION_TYPES.VIDEO_EDITOR_ASSIGNED:
      return `${fullName} has assigned you to ${resourceName}.`;
    case NOTIFICATION_TYPES.PROJECT_OWNER_CHANGED:
      return `${fullName} has made you the owner of ${resourceName}.`;
    case NOTIFICATION_TYPES.PROJECT_CUSTOM_DUE_DATE:
      return `We've changed the due date on ${resourceName}.`;
    case NOTIFICATION_TYPES.COMMENT_MENTION:
      return `${fullName} has mentioned you.`;
    case NOTIFICATION_TYPES.VIDEO_APPROVED:
      if (!resource) {
        return `${resourceName} has been completed. You can now start work on any social iterations.`;
      }
      return `${_get(resource, 'outputs[0].name') || resourceName} has been completed. You can now start work on any social iterations.`;
    case NOTIFICATION_TYPES.PROJECT_BACK_TO_DRAFT:
      return `We've moved ${resourceName} back to building.`;
    case NOTIFICATION_TYPES.ORG_PACKAGE_CHANGED:
      return 'Your subscription package has been modified.';
    case NOTIFICATION_TYPES.RUSH_EDIT_CANCELLED:
      return `${fullName} is unable to complete your rush edit.`;
    case NOTIFICATION_TYPES.NEW_RESOURCE_COMMENT:
      return `${fullName} has left a comment.`;
    case NOTIFICATION_TYPES.ORG_NO_CREDITS_AM:
    case NOTIFICATION_TYPES.ORG_NO_CREDITS_CLIENT:
      return 'Last project credit has been used. See more.';
    case NOTIFICATION_TYPES.VIDEO_CHANGES_REQUESTED:
      return `${fullName} has submitted your changes for review in ${resourceName}.`;
    case NOTIFICATION_TYPES.ORG_CREDITS_CHANGED:
      return 'We’ve changed your organisation’s credits';
    case NOTIFICATION_TYPES.PROJECT_CREDITS_ADJUSTED:
      return `${fullName} has adjusted your project’s credit usage.`;
    case NOTIFICATION_TYPES.ADD_MORE_ASSETS:
      return `This project ${resourceId} has had assets added to it while in progress. Please check the project and contact the user if this will affect their SLA.`;
    case NOTIFICATION_TYPES.PROJECT_APPROVED_FOR_CAPTIONING:
      return `Project ${resourceName} has been approved for captioning. Please prioritise the completion of this.`;
    case NOTIFICATION_TYPES.APPROVE_STORYBOARD:
      return `The storyboard for ${resourceName} has been approved. This is now in your traffic queue ready for animation.`;
    case NOTIFICATION_TYPES.STORYBOARD_REJECTED:
      return `${fullName} requested changes.`;
    case NOTIFICATION_TYPES.REQUEST_SIGN_OFF:
      return `${fullName} requested sign off`;
    case NOTIFICATION_TYPES.REQUEST_ASSISTANCE:
      return `${fullName} has requested your assistance.`;
    case NOTIFICATION_TYPES.REJECT_SIGN_OFF:
      return `${fullName} has rejected sign-off`;
    case NOTIFICATION_TYPES.APPROVED_SIGN_OFF:
      return `Project ${resourceName} has been approved and completed.`;
    default:
      return '';
  }
};

export default buildNotificationString;

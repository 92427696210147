// @flow
import { useEffect } from 'react';

function PreLoaderComponent({ requests, globalStore }: Object) {
  const { updateObjectForKey, getObjectForKey } = globalStore;

  const cacheProData = async () => {
    const { getFilteredVideos } = requests;

    const { data } = await getFilteredVideos({
      USER: ['ME'],
      limit: 12,
      offset: 0,
      sortBy: 'updatedAtDesc',
      product: 'PRO'
    });

    // Don't overwrite cache if data already cached
    const existingData = getObjectForKey('/pro');
    if (existingData.videos) {
      return;
    }

    const { count, videos } = data.getFilteredVideos || {};
    if (videos) {
      updateObjectForKey('/pro', {
        loading: false,
        count,
        videos,
        users: [],
        videoCountCreatedByUser: count
      });
    }
  };

  // Run the Platform's various long queries and cache relevant data in the global state store
  useEffect(() => {
    const existingData = getObjectForKey('/pro');
    if (!existingData.videos) {
      setTimeout(cacheProData, 500);
    }
  }, []);

  return null;
}

export default PreLoaderComponent;

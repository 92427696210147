// @flow
import React, { memo } from 'react';
import type { Node } from 'react';
import { Button, Text } from '@shootsta/common-react';
import type { RouterHistory } from '@shootsta/common-flow-types';

import '../styles/_missing-fields-modal.scss';

const DEFAULT_BUTTON_TEXT = 'Update';

type FieldBlockProps = {
  title: string,
  subtitle?: Node,
  buttonText?: string,
  isButtonDisabled?: boolean,
  showActionButton?: boolean,
  onClose: Function,
  stage: number,
  history: RouterHistory,
  callback: Function
};

function FieldBlock({
  title,
  subtitle,
  onClose,
  showActionButton = true,
  isButtonDisabled,
  buttonText = DEFAULT_BUTTON_TEXT,
  callback
}: FieldBlockProps) {
  const onUpdate = () => {
    onClose();
    if (typeof callback !== 'function') return;
    callback();
  };

  return (
    <div className="missing-fields-modal__fields__field" key={title}>
      <div className="missing-fields-modal__fields__field__text">
        <Text button>{title}</Text>
        <Text noMargin="bottom">{subtitle}</Text>
      </div>
      {showActionButton && (
        <Button disabled={isButtonDisabled} onClick={onUpdate} type="SECONDARY">
          {buttonText}
        </Button>
      )}
    </div>
  );
}

export default memo(FieldBlock);

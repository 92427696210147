/* eslint-disable no-useless-escape */

import noSubsBannerImage from '../../../../assets/images/homepageAssets/bannerAssets/banner-no-subs.svg';
import proBannerImage from '../../../../assets/images/homepageAssets/bannerAssets/banner-pro.svg';
import castBannerImage from '../../../../assets/images/homepageAssets/bannerAssets/banner-cast.svg';
import elevateBannerImage from '../../../../assets/images/homepageAssets/bannerAssets/banner-elevate.svg';
import proButtonImage from '../../../../assets/images/homepageAssets/bannerAssets/banner-pro-button.svg';
import castButtonImage from '../../../../assets/images/homepageAssets/bannerAssets/banner-cast-button.svg';
import appleAppStore from '../../../../assets/images/homepageAssets/bannerAssets/apple-app-store.svg';
import androidAppStore from '../../../../assets/images/homepageAssets/bannerAssets/android-app-store.svg';

export default {
  NO_SUBS: {
    HEADING: 'Getting started can be tough. Need some ideas?',
    IMAGE: noSubsBannerImage,
    CTA_TEXT: 'View Inspirations',
    CTA_DEFAULT_PATH: '/inspiration'
  },
  NO_SUBS_V2: {
    HEADING: 'The possibilities are endless. See for yourself.',
    IMAGE: noSubsBannerImage,
    CTA_TEXT: 'View Inspirations',
    CTA_DEFAULT_PATH: '/inspiration'
  },
  PRO: {
    HEADING:
      "Looks like you haven't created any Pro projects yet. Let's get started!",
    IMAGE: proBannerImage,
    CTA_TEXT: 'Create New',
    CTA_DEFAULT_PATH: '/pro'
  },
  PRO_EXPIRED: {
    HEADING:
      'Looks like your Pro subscription has expired. Contact your org administrator for access.',
    HEADING_ADMIN: 'Looks like your Pro subscription has expired.',
    IMAGE: proBannerImage,
    CTA_TEXT: 'Subscribe',
    CTA_DEFAULT_PATH: '/pro'
  },
  CAST: {
    HEADING: "Looks like you haven't created any Casts yet. Let's get started!",
    IMAGE: castBannerImage,
    CTA_TEXT: 'Create New',
    CTA_DEFAULT_PATH: '/cast'
  },
  CAST_EXPIRED: {
    HEADING:
      'Looks like your Cast subscription has expired. Contact your org administrator for access.',
    HEADING_ADMIN: 'Looks like your Cast subscription has expired.',
    IMAGE: castBannerImage,
    CTA_TEXT: 'Subscribe',
    CTA_DEFAULT_PATH: '/cast'
  },
  ELEVATE: {
    HEADING: "Looks like you haven't created any Elevates yet. Find out more.",
    IMAGE: elevateBannerImage,
    CTA_DEFAULT_PATH: '/elevate',
    CTA_LIST: [
      {
        ctaImage: appleAppStore,
        ctaPath: 'https://apps.apple.com/us/app/shootsta-elevate/id1503623435'
      },
      {
        ctaImage: androidAppStore,
        ctaPath:
          'https://play.google.com/store/apps/details?id=com.shootsta.elevate'
      }
    ]
  },
  PRO_CAST_AGGREGATED: {
    HEADING:
      "Looks like you haven't created any Pro projects or Casts yet. Let's get started!",
    CTA_LIST_BUTTON_TEXT: 'Create New',
    CTA_DEFAULT_PATH: '/pro',
    CTA_LIST: [
      {
        ctaImage: proButtonImage,
        ctaPath: '/pro'
      },
      {
        ctaImage: castButtonImage,
        ctaPath: '/cast'
      }
    ]
  }
};

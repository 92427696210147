const {
  SHOOTSTA_ALIAS,
  SHOOTSTA_DEMO,
  RECRUITMENT_ALIAS,
  SHOOTSTAEVENTS_ALIAS,
  SHOOTSTAMARKETING_ALIAS,
  SHOOTSTA_CSM,
  ELEVATETESTING_ALIAS,
  ELEVATEDEMOS_ALIAS,
  UNDERCOVERINC_ALIAS
} = require('./org-aliases');

const SHOOTSTA_HYPER_BATCH_WORKSPACES = [
  SHOOTSTA_ALIAS,
  SHOOTSTA_DEMO,
  RECRUITMENT_ALIAS,
  SHOOTSTAEVENTS_ALIAS,
  SHOOTSTAMARKETING_ALIAS,
  SHOOTSTA_CSM,
  ELEVATETESTING_ALIAS,
  ELEVATEDEMOS_ALIAS,
  UNDERCOVERINC_ALIAS
];

export { SHOOTSTA_HYPER_BATCH_WORKSPACES };

// @flow
import React from 'react';
import { AuthorisationConsumer } from '@shootsta/common-react';
import BookingsLinkComponent from '../components/BookingsLink';

function BookingsLink() {
  return (
    <AuthorisationConsumer>
      {({ selectedOrganisation }) => (
        <BookingsLinkComponent selectedOrganisation={selectedOrganisation} />
      )}
    </AuthorisationConsumer>
  );
}

export default BookingsLink;

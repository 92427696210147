import type { Node } from 'react';

type Props = {
  condition: boolean,
  failWith?: Node,
  children?: Node
};

/**
 *
 * Renders a conditional component
 *
 * @param {boolean} condition
 * @param {Element} failWith
 * @param {Element} children
 *
 * @return {Element|null}
 */
export default function When({ condition, failWith, children }: Props) {
  if (!condition) {
    return failWith || null;
  }

  return children || null;
}

import React, { Component } from 'react';
import { Loader } from '@shootsta/common-react';
import _omit from 'lodash.omit';
import getAlias from '@shootsta/get-alias';
import getFingerprint from '../../utils/getFingerprint';
import {
  AUTHORIZATION_CODE,
  RETURN_TO,
  GHOST,
  TTL
} from '../../../../constants';
import LostPage from '../../components/LostPage';

export interface CredentialsUser {
  userId: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  imageUrl?: string;
  initialSetup?: boolean;
  isAvailable?: boolean;
  isAnonymous?: boolean;
  preferences?: Object;
}

type Credentials = {
  auth: boolean,
  token?: string,
  user?: CredentialsUser
};

function setToken(credentials: Object) {
  localStorage.setItem('credentials', JSON.stringify(credentials));
}

type IdentityAuthProps = {
  workspaceLogin: Function
};

type IdentityAuthState = {
  error: boolean
};

export default class IdentityAuth extends Component<
  IdentityAuthProps,
  IdentityAuthState
> {
  constructor(props: IdentityAuthProps) {
    super(props);

    this.state = {
      error: false
    };
  }

  componentDidMount() {
    return this.authenticate();
  }

  loginIntoWorkspace = async ({
    fingerprint,
    alias,
    code
  }: {
    fingerprint: string,
    alias: string,
    code: string
  }): Promise<Credentials> => {
    const { workspaceLogin } = this.props;

    const { data, error } = await workspaceLogin({
      fingerprint,
      alias,
      authorizationCode: code
    });

    if (!data || !data.workspaceLogin || !data.workspaceLogin.user || error) {
      throw new Error(error);
    }

    const credentials = !data.workspaceLogin.user.isAnonymous
      ? data.workspaceLogin
      : _omit(data.workspaceLogin, 'user.initialSetup');

    setToken(credentials);

    return credentials;
  };

  authenticate = async () => {
    try {
      const alias = getAlias();
      const fingerprint = await getFingerprint();

      const queryParams = new URLSearchParams(window.location.search);

      const code = queryParams.get(AUTHORIZATION_CODE);
      const returnTo = queryParams.get(RETURN_TO);
      const redirectTTL = queryParams.get(TTL);
      const ghost = queryParams.get(GHOST);

      if (
        Array.isArray(returnTo) ||
        Array.isArray(ghost) ||
        Array.isArray(code)
      ) {
        throw new Error('Invalid query params.');
      }

      if (!fingerprint || !alias || !code) {
        throw new Error('Login failed.');
      }

      await this.loginIntoWorkspace({ fingerprint, alias, code });

      const returnToWithProtocol =
        returnTo && returnTo.match(/^https?:\/\//)
          ? returnTo
          : `${window.location.protocol}//${returnTo || ''}`;

      const returnToURL = new URL(
        returnTo ? returnToWithProtocol : window.location.origin
      );

      const nextQueryParams = {
        [GHOST]: ghost,
        [TTL]: redirectTTL
      };

      Object.keys(nextQueryParams).forEach((key) => {
        if (nextQueryParams[key]) {
          returnToURL.searchParams.set(key, nextQueryParams[key]);
        }
      });

      window.history.replaceState({}, 'Shootsta', returnToURL.href);
      window.location.reload();
    } catch (e) {
      this.setState({ error: true });
    }
  };

  render() {
    const { error } = this.state;

    if (error) {
      return <LostPage />;
    }

    return <Loader />;
  }
}

export default {
  USER_HOMEPAGE: 'USER_HOMEPAGE',
  SCRIPTS: 'SCRIPTS',
  STORYBOARDS: 'STORYBOARDS',
  DAM: 'DAM',
  INTERNAL: 'INTERNAL',
  ORGANISATION: 'ORGANISATION',
  VIDEO_TOOL: 'VIDEO_TOOL',
  VIDEO_REVIEW: 'VIDEO_REVIEW',
  INSPIRATIONS: 'INSPIRATIONS',
  USER_SETTINGS: 'USER_SETTINGS',
  BOOKINGS: 'BOOKINGS',
  CAST: 'CAST',
  ELEVATE: 'ELEVATE',
  CLIENT_STATISTICS_REPORTS: 'CLIENT_STATISTICS_REPORTS',
  MANAGE_ORGANISATION: 'MANAGE_ORGANISATION',
  BROWSE_COMPLETED_VIDEOS_INTERNAL: 'BROWSE_COMPLETED_VIDEOS_INTERNAL'
};

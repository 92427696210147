import React from 'react';
import cx from 'classnames';
import { Text, ElevatedContainer, Button } from '@shootsta/common-react';
import './_product-banner.scss';

type ProductBannerProps = {
  heading?: string,
  ctaText?: string,
  bannerImage?: string,
  ctaDefaultPath: string,
  ctaClick: Function,
  ctaClickExternal?: Function,
  ctaList?: Array<Object>,
  ctaListButtonText?: string,
  ctaListDisplayColumn?: boolean
};

function ProductBanner({
  heading,
  ctaText,
  bannerImage,
  ctaDefaultPath,
  ctaClick,
  ctaClickExternal,
  ctaList,
  ctaListButtonText,
  ctaListDisplayColumn
}: ProductBannerProps) {
  return (
    <ElevatedContainer rounded className="product-banner">
      {ctaList ? (
        <div className="product-banner__banner-content">
          <Text className="product-banner__banner-content__header-title">
            {heading}
          </Text>
          <ul
            className={cx(
              'product-banner__banner-content__cta-list-container',
              {
                'product-banner__banner-content__cta-list-container--stack-column':
                  ctaListDisplayColumn
              }
            )}
          >
            {ctaListButtonText && (
              <li className="product-banner__banner-content__cta-list-container__cta-list-button-text">
                <Text>{ctaListButtonText}</Text>
              </li>
            )}
            {ctaList.map((cta) => {
              const { ctaImage, ctaPath } = cta;
              const ctaType = ctaClickExternal || ctaClick;

              return (
                <li
                  key={ctaPath}
                  className="product-banner__banner-content__cta-list-container__cta-list-button"
                  style={{ backgroundImage: `url(${ctaImage})` }}
                  onClick={() => ctaType(ctaPath)}
                />
              );
            })}
          </ul>
          <div
            className="product-banner__banner-content__default-click-area"
            onClick={() => ctaClick(ctaDefaultPath)}
          />
        </div>
      ) : (
        <div
          className="product-banner__banner-content"
          onClick={() => ctaClick(ctaDefaultPath)}
        >
          <Text className="product-banner__banner-content__header-title">
            {heading}
          </Text>
          {ctaText && (
            <Button className="product-banner__banner-content__cta-default">
              {ctaText}
            </Button>
          )}
        </div>
      )}
      {bannerImage && (
        <div
          className="product-banner__banner-background-image"
          style={{ backgroundImage: `url(${bannerImage})` }}
        />
      )}
    </ElevatedContainer>
  );
}

export default ProductBanner;

import React from 'react';
import { RequestProvider } from '@shootsta/common-react';
import IdentityAuthComponent from '../components/IdentityAuth';
import { ghostStartApi, workspaceLoginApi } from '../../../../api';

export default function IdentityAuth() {
  return (
    <RequestProvider
      requests={{
        workspaceLogin: workspaceLoginApi(),
        ghostStart: ghostStartApi()
      }}
    >
      {(requests) => <IdentityAuthComponent {...requests} />}
    </RequestProvider>
  );
}

import React, { useEffect, useState } from 'react';
import { SizeMe } from 'react-sizeme';
import banner from '../../../../../assets/images/homepageAssets/banner.svg';
import logo from '../../../../../assets/images/homepageAssets/Shootsta-AI-logo.svg';
import './_article-banner.scss';
import type { RouterHistory as History } from 'react-router-dom';
import {
  Button,
  Carousel,
  ElevatedContainer,
  List,
  Text,
  Icon,
  // SelectProjectTypeModel,
  ContentLoader
} from '@shootsta/common-react';

const MEDIA_SIZES_SMALL = 639;

type ArticleBannerProps = {
  history: History,
  getBannerArticles: Function,
  organisationId: string
};

function ArticleBanner({
  history,
  getBannerArticles,
  organisationId
}: ArticleBannerProps) {
  // const [selectAticleUrl, setSelectAticleUrl] = useState();
  const [articles, setArticles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const loadArticles = async () => {
      try {
        setIsLoading(true);
        const { data } = await getBannerArticles({ organisationId });
        setArticles(data?.getBannerArticles ?? []);
      } catch (e) {
        console.log('error', e);
        setArticles([]);
      } finally {
        setIsLoading(false);
      }
    };
    if (getBannerArticles && organisationId) {
      loadArticles();
    }
  }, [getBannerArticles, organisationId]);

  const ArticleBannerCardItem = ({ topics, title, link }) => {
    return (
      <List.CardItem key={link}>
        <a className="card-link">
          <div className="card-text">
            {topics.map((topic) => (
              <Text key={topic}>{topic}</Text>
            ))}
          </div>
          <div className="article-banner__header">
            <Text className="article-banner__header-title">{title}</Text>
            <a
              href={link}
              target="_blank"
              className="article-banner__header-span"
            >
              <Icon name="linkext" size={16} />
            </a>
          </div>
          <Button
            className="artical-box___card-button"
            onClick={() => history.push(`/pro/builder/new?articleUrl=${link}`)}
          >
            Generate
          </Button>
        </a>
      </List.CardItem>
    );
  };

  const ArticleLoader = () => (
    <List.CardItem>
      <ContentLoader
        height={140}
        width={322}
        preserveAspectRatio="xMinYMid meet"
      >
        <rect x="10" y="0" width="104" height="12" rx="10" ry="10" />
        <rect x="10" y="25" width="300" height="14" rx="10" ry="10" />
        <rect x="10" y="45" width="300" height="14" rx="10" ry="10" />
        <rect x="10" y="65" width="272" height="14" rx="10" ry="10" />
        <rect x="10" y="105" width="91" height="34" rx="10" ry="10" />
      </ContentLoader>
    </List.CardItem>
  );

  return (
    <SizeMe>
      {({ size: { width } }) => (
        <ElevatedContainer rounded className="article-banner">
          <div
            className="artical-box"
            style={{
              background: `url(${banner}), linear-gradient(45deg, #27BAA1, #7B66FF)`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPosition: 'center left'
            }}
          >
            <div>
              <img src={logo} className="logo" />
              <Text>
                Transform articles to videos. <br />
                Let AI be your co-creator.
              </Text>
              <Button
                className="artical-box___button"
                onClick={() => history.push('/article-to-brief')}
              >
                Browse article library
              </Button>
            </div>
          </div>
          <Carousel
            itemWidth="33%"
            slidesToShow={width < MEDIA_SIZES_SMALL ? 1 : 3}
          >
            {isLoading
              ? Array.from({ length: 3 }, (_, index) => (
                  <ArticleLoader key={index} />
                ))
              : articles.map((card) => ArticleBannerCardItem(card))}
          </Carousel>
          {/* RC-1131: Skip triggering the modal and directly open the project selection page */}
          {/* <SelectProjectTypeModel
            visible={selectAticleUrl?.length}
            onClose={() => setSelectAticleUrl(undefined)}
            history={history}
            articleUrl={selectAticleUrl ?? ''}
          /> */}
        </ElevatedContainer>
      )}
    </SizeMe>
  );
}
export default ArticleBanner;

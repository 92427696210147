import gql from 'graphql-tag';

const getFeedbackLinksQuery = gql`
  query getFeedbackLinks($fields: [FeedbackLinkField!]!) {
    getFeedbackLinks(fields: $fields) {
      id
      topbarFeedbackIconLink
    }
  }
`;

export default () => ({
  query: getFeedbackLinksQuery,
  module: 'system-asset-library',
  ignoreCache: true,
  buildVariables: (fields: any) => ({
    fields
  })
});

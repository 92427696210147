import { gql } from 'apollo-boost';

const GHOST_START_QUERY = gql`
  query GhostStart($email: String!) {
    ghostStart(email: $email) {
      token
      auth
      user {
        email
        userId
        firstName
        lastName
        preferences
        imageUrl
        isAvailable
      }
    }
  }
`;

export default () => ({
  query: GHOST_START_QUERY,
  module: 'identity',
  buildVariables: ({ email }: { email: string }) => ({ email })
});

import React from 'react';
import AdditionalFieldCarouselComponent from '../components/AdditionalFieldCarousel';
import { UploaderConsumer } from '@shootsta/common-react';

function AdditionalFieldCarousel(props) {
  return (
    <UploaderConsumer>
      {({ assetsMap }) => (
        <AdditionalFieldCarouselComponent assetsMap={assetsMap} {...props} />
      )}
    </UploaderConsumer>
  );
}

export default AdditionalFieldCarousel;

export default {
  PRO: {
    DEFAULT_PATH: '/pro',
    PRODUCT_NAME: 'Pro',
    CAROUSEL_TITLE: 'Recent Pro Projects'
  },
  CAST: {
    DEFAULT_PATH: '/cast',
    PRODUCT_NAME: 'Cast',
    CAROUSEL_TITLE: 'Recent Casts'
  },
  ELEVATE: {
    DEFAULT_PATH: '/elevate',
    PRODUCT_NAME: 'Elevate',
    CAROUSEL_TITLE: 'Recent Elevates'
  }
};

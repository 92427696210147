import React, { useEffect } from 'react';
import { credentials } from '@shootsta/client-auth';
import { redirectToIdentityApp } from '../../../utils';

export default function IdentityLogout() {
  useEffect(() => {
    credentials.clear();

    redirectToIdentityApp();
  }, []);

  return <></>;
}

import howToStepOne from '../../../../assets/images/homepageAssets/scrumBoard.svg';
import howToStepTwo from '../../../../assets/images/homepageAssets/onlineArticles.svg';
import howToStepThree from '../../../../assets/images/homepageAssets/onlineMedia.svg';
import howToStepFour from '../../../../assets/images/homepageAssets/socialNotifications.svg';

export default [
  {
    text: 'Discover our newest features',
    imagePath: howToStepOne,
    intercomTourId: 252190
  },
  {
    text: 'Shootsta’s Latest News',
    imagePath: howToStepTwo,
    link: 'https://shootsta.com/resources/'
  },
  {
    text: 'Why Shootsta?',
    imagePath: howToStepThree,
    link: 'https://shootsta.com/'
  },
  {
    text: 'Take a tour of our suite of apps and services',
    imagePath: howToStepFour,
    intercomTourId: 253015
  }
];

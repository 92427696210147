import React, { useMemo } from 'react';
import { Text, ChipListV2, Chip, Input } from '@shootsta/common-react';
import { TOPICS } from '@shootsta/common-domain-data';

type Step1Props = {
  topics: Array<string>,
  setTopics: Function,
  industry: string,
  setindustry: Function
};

const Step1 = ({ topics, setTopics, industry, setindustry }: Step1Props) => {
  const onClick = (topic) => {
    setTopics(topic.selection);
  };
  return (
    <div className="wizard-body__kyc-content">
      <div className="wizard-body__finish-content__item">
        <Text label darkTheme required>
          Which topics would you like to view on your personalised feed?
        </Text>
        <ChipListV2
          onChange={onClick}
          value={topics}
          suffix="addfat"
          suffixAfterSelect="success"
          multiple
        >
          {TOPICS.map((topic) => (
            <Chip key={topic} value={topic}>
              {topic}
            </Chip>
          ))}
        </ChipListV2>
      </div>

      <Input
        className="wizard-body__kyc-content"
        isFullWidth
        label="Industry"
        labelProps={{ required: true }}
        placeholder="Indicate industry"
        defaultValue={industry}
        value={industry}
        onChange={(e) => setindustry(e.target.value)}
      />
    </div>
  );
};

export default Step1;

import { gql } from 'apollo-boost';

export const getNotificationsAndCountQuery = gql`
  query GetNotificationsAndCount($offset: Int) {
    getNotificationsAndCount(offset: $offset) {
      count
      notifications {
        notificationId
        sourceUserId
        userId
        type
        link
        read
        createdAt
        updatedAt
        resource
        sourceUser {
          userId
          imageUrl
          firstName
          lastName
        }
      }
    }
  }
`;

export default () => ({
  query: getNotificationsAndCountQuery,
  module: 'notifications',
  ignoreCache: true,
  buildVariables: ({ offset }: { offset: number }) => ({ offset })
});

import { gql } from 'apollo-boost';

export const markAllAsReadMutation = gql`
  mutation MarkAllAsRead {
    markAllAsRead
  }
`;

export default () => ({
  mutation: markAllAsReadMutation,
  module: 'notifications',
  buildVariables: () => ({})
});

import { gql } from 'apollo-boost';

const FORGOT_PASSWORD_QUERY = gql`
  query forgotPassword($email: String!, $alias: String) {
    forgotPassword(email: $email, alias: $alias)
  }
`;

export default () => ({
  mutation: FORGOT_PASSWORD_QUERY,
  module: 'users',
  buildVariables: ({ email, alias }: { email: string, alias: string }) => ({
    email,
    alias
  })
});

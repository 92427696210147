import React from 'react';
import { Route } from 'react-router-dom';
import { AuthorisationConsumer, NotFound } from '@shootsta/common-react';

export default ({
  path,
  permission,
  component,
  exact
}: {
  path: string,
  permission: string,
  component: any,
  exact?: boolean
}) => (
  <AuthorisationConsumer>
    {({ selectedOrganisation: { permissions: orgPermissions } = {} }) => {
      if (!orgPermissions.includes(permission)) {
        return <NotFound />;
      }

      return <Route path={path} component={component} exact={exact} />;
    }}
  </AuthorisationConsumer>
);

// @flow

import React, { Component } from 'react';
import { credentials } from '@shootsta/client-auth';
import getOrgAlias from '@shootsta/get-alias';
import { Error } from '@shootsta/common-react';
import queryString from 'query-string';
import _get from 'lodash.get';
import type { Location, RouterHistory } from 'react-router-dom';
import type { Node } from 'react';
import { redirectToSingleSignOn, setSSOLocalStore } from '../../../../utils';
import { SSO_LOGIN_CREDENTIALS_FLAG } from '../../../constants';

type SSOAuthProps = {
  getOrgSSOConfig: Function,
  location: Location,
  history: RouterHistory,
  children: Node
};

type SSOAuthState = {
  shouldRenderChildren: boolean,
  error?: string
};

export default class SSOAuth extends Component<SSOAuthProps, SSOAuthState> {
  constructor(props: SSOAuthProps) {
    super(props);

    this.state = { shouldRenderChildren: false };
  }

  async componentDidMount() {
    const { getOrgSSOConfig, location, history } = this.props;
    const orgAlias = getOrgAlias();
    const { data, error } = orgAlias ? await getOrgSSOConfig(orgAlias) : {};

    if (error) {
      return void this.setState({ error });
    }

    const ssoEnabled = orgAlias
      ? _get(data, 'getOrganisationSSOConfig.ssoConfig.ssoEnabled')
      : false;

    const sloDisabled = orgAlias
      ? _get(data, 'getOrganisationSSOConfig.ssoConfig.sloDisabled', false)
      : false;
    const existingCredentials = credentials.get();
    const { ghost, auth } = existingCredentials || {};

    // Handling for a SSO user already authenticated, hard refreshing the page
    if (ssoEnabled && auth && ghost === void 0) {
      setSSOLocalStore({ ssoEnabled: true, sloDisabled });
      return void this.setState({ shouldRenderChildren: true });
    }

    const urlQuery = queryString.parse(location.search);

    // We set a ghost value for ghosting to skip sso
    // If user is already authenticated (auth = true) skip sso
    // Should probably come up with a better solution to this
    if (!ssoEnabled || ghost || auth || urlQuery.skipsso) {
      return void this.setState({ shouldRenderChildren: true });
    }

    // If sso query param is not present means a SSO user
    // is directly trying to use HUB login rather than his SSO login
    if (!urlQuery.sso) {
      return void redirectToSingleSignOn(location.pathname);
    }

    const decodedData = atob(String(urlQuery.sso));

    credentials.set({
      ...JSON.parse(decodedData),
      // The ssoLogin flag indicates that the user is signed in using SAML SSO
      [SSO_LOGIN_CREDENTIALS_FLAG]: true
    });
    setSSOLocalStore({ ssoEnabled: true, sloDisabled });

    history.push(location.pathname);

    this.setState({ shouldRenderChildren: true });
  }

  render() {
    const { shouldRenderChildren, error } = this.state;
    const { children } = this.props;

    if (error) {
      return <Error />;
    }

    if (!shouldRenderChildren) {
      return null;
    }

    return children;
  }
}

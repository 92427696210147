import { CompactView, Login, Modal } from '@bynder/compact-view';

interface BynderAsset {
  databaseId: string;
  name: string;
  extensions: string[];
  type: string;
  previewUrls: string[];
  description: string | null;
  fileSize: number;
  derivatives: {
    thumbnail: string;
    webImage: string;
  };
}

enum AssetOrigin {
  BYNDER = 'BYNDER'
}

interface ToAddAsset {
  filename: string;
  title: string;
  assetOrigin: AssetOrigin;
  metaData: string;
}

const assetFieldSelection = `
  databaseId
  name
  url
  originalUrl
  fileSize
  description
  type
  extensions
  derivatives {
    thumbnail
    webImage
  }
  ... on Video {
    previewUrls
  }
`;

const getPreviewUrl = (asset: BynderAsset) => {
  switch (asset.type) {
    case 'IMAGE':
      return asset.derivatives.thumbnail;

    case 'VIDEO':
      return asset.previewUrls[0];

    case 'DOCUMENT':
    case 'AUDIO':
      return asset.derivatives.webImage;
  }
};

const getThumnbnailUrl = (asset: BynderAsset) => {
  switch (asset.type) {
    case 'VIDEO':
      return asset.derivatives.thumbnail;

    case 'AUDIO':
      return asset.derivatives.webImage;

    default:
      return null;
  }
};

const BynderDAM = ({
  bynderPortalUrl,
  isOpen = false,
  addAssetsToVideo,
  onClose
}: {
  bynderPortalUrl: string;
  isOpen?: boolean;
  organisationId: string;
  addAssetsToVideo: (assets: ToAddAsset[]) => void;
  onClose: () => void;
}) => {
  const onSuccess = (assets: unknown[]) => {
    const prepareAssetsToAdd = (assets as BynderAsset[]).map(
      (it: BynderAsset) => ({
        filename: `${it.name}.${it.extensions[0]}`,
        title: it.description || it.name,
        assetOrigin: AssetOrigin.BYNDER,
        metaData: {
          size: it.fileSize,
          type: `${it.type.toLowerCase()}/${it.extensions[0]}`,
          sourceUrl: getPreviewUrl(it),
          ...(getThumnbnailUrl(it) && { thumbnail: getThumnbnailUrl(it) }),
          bynderAssetId: it.databaseId
        }
      })
    );

    addAssetsToVideo(prepareAssetsToAdd);
    console.log(prepareAssetsToAdd);

    onClose();
  };

  if (!bynderPortalUrl) {
    console.error('Bynder portal URL is not provided');
    return null;
  }

  return (
    <Login portal={{ url: bynderPortalUrl }} language="en_US">
      <Modal isOpen={isOpen} onClose={onClose} style={{ width: '100%' }}>
        <CompactView
          language="en_US"
          onSuccess={onSuccess}
          assetFieldSelection={assetFieldSelection}
          theme={{
            colorPrimary: '#27BAA1',
            colorButtonPrimary: '#27BAA1',
            colorButtonPrimaryActive: '#27BAA1',
            colorButtonPrimaryHover: '#27BAA1'
          }}
        />
      </Modal>
    </Login>
  );
};

export default BynderDAM;

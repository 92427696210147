import moment from 'moment';

export const getRelativeDate = (toDate: string, fromDate?: string) => {
  const fromMoment = fromDate ? moment(fromDate) : moment();
  const reference = moment(toDate);

  const today = fromMoment.clone().startOf('day');
  const yesterday = fromMoment.clone().subtract(1, 'days').startOf('day');

  return {
    isToday: reference.isSame(today, 'd'),
    isYesterday: reference.isSame(yesterday, 'd')
  };
};

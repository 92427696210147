import React from 'react';
import { withRouter } from 'react-router-dom';

import MissingFieldsModalComponent from '../components/MissingFieldsModal';

type MissingField = {
  field: string,
  stage: number,
  callback: Function
};

type MissingFieldsModalProps = {
  history: any,
  onClose: Function,
  missingFields: Array<MissingField>
};

function MissingFieldsModal(props: MissingFieldsModalProps) {
  return <MissingFieldsModalComponent {...props} />;
}

export default withRouter(MissingFieldsModal);

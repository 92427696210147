import React from 'react';
import { Button, Icon, Input } from '@shootsta/common-react';
import isEmail from 'validator/lib/isEmail';
import LoginHeader from '../LoginHeader';
import { onEnterPressed } from '../../../../../utils';

type Props = {
  onEmailFormSubmit: Function,
  canSubmitEmail: Function,
  submitting?: boolean,
  setEmail: Function,
  email?: string,
  error?: string
};

const EmailForm = ({
  onEmailFormSubmit,
  canSubmitEmail,
  submitting,
  setEmail,
  email,
  error
}: Props) => (
  <div className="login-form">
    <div className="login-form__body">
      <LoginHeader
        title="Let's find your space"
        subtitle="Start by choosing the email you use to login"
      />
      {error ? (
        <div className="login-form__body__error">
          <Icon name="alert" color="thunderBird" size={15} />
          <p>{error}</p>
        </div>
      ) : null}
      <form className="login-form__body__form">
        <Input
          data-cy="workspace-email-input"
          id="email-input"
          className="login-form__body__field"
          placeholder="name@company.com"
          value={email}
          onChange={setEmail}
          prefix="email"
          label="Email Address"
          autoComplete="username"
          validate={(value) => !value || isEmail(value)}
          errorMessage="That doesn't look like an email address."
          onKeyDown={onEnterPressed({ onClick: onEmailFormSubmit })}
        />
      </form>
      <Button
        data-cy="find-workspace-submit"
        className="login-form__body__submit"
        disabled={!canSubmitEmail(email)}
        onClick={onEmailFormSubmit}
        busy={submitting}
      >
        Continue
      </Button>
    </div>
  </div>
);

export default EmailForm;

import { gql } from 'apollo-boost';

const UPDATE_USER = gql`
  mutation updateUser($userId: String!, $input: UpdateUserInput!) {
    updateUser(userId: $userId, input: $input) {
      isAvailable
    }
  }
`;

export default (userId: string) => ({
  mutation: UPDATE_USER,
  module: 'users',
  buildVariables: ({ isAvailable }: { isAvailable: boolean }) => ({
    userId,
    input: { isAvailable }
  })
});

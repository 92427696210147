import React from 'react';
import { ElevatedContainer, Text, SelectionList } from '@shootsta/common-react';
import AdditionalFieldCarousel from '../../AdditionalFieldCarousel';
import '../styles/_additional-fields-preview.scss';

export type Field = {
  fieldId: string,
  label: string,
  description: string,
  assetId?: string
};

export type AdditionalField = {
  additionalFieldId: string,
  label: string,
  description: string,
  fields: Field[] | null,
  type: 'RADIO' | 'CHECKBOX',
  isMandatory: boolean
};

type AdditionalFieldsPreviewProps = {
  additionalFields: [AdditionalField],
  onExit: () => void
};

function AdditionalFieldsPreview({
  additionalFields,
  onExit
}: AdditionalFieldsPreviewProps) {
  return (
    <div className="additional-fields-preview-container">
      <ElevatedContainer rounded>
        <div className="additional-fields-preview-heading">
          <Text noMargin heading4>
            Additional Details
          </Text>
        </div>
        <div className="additional-fields-preview-content">
          {additionalFields?.map(
            ({ label, description, fields, type, isMandatory }, index) => {
              const hasAsset = fields?.some((field) => field.assetId);

              return (
                <div key={index}>
                  {hasAsset ? (
                    <div className="project-additional-field__carousel">
                      <AdditionalFieldCarousel
                        onSelect={() => void 0}
                        description={description}
                        label={label}
                        fields={fields}
                        type={type}
                        isMandatory={isMandatory}
                      />
                    </div>
                  ) : (
                    <div className="project-additional-field__selection-list">
                      <SelectionList
                        fields={fields}
                        onChange={() => void 0}
                        description={description}
                        label={label}
                        type={type}
                        isMandatory={isMandatory}
                      />
                    </div>
                  )}
                </div>
              );
            }
          )}
        </div>
      </ElevatedContainer>
    </div>
  );
}

export default AdditionalFieldsPreview;

import React from 'react';
import { withRouter } from 'react-router-dom';
import {
  AuthorisationConsumer,
  RequestProvider,
  PageHeader,
  GlobalStoreConsumer,
  requests
} from '@shootsta/common-react';

import { credentials } from '@shootsta/client-auth';
import {
  getUserRecentActivityApi,
  getAssetThumbnailApi,
  getUserRecentElevatesApi,
  getBannerArticlesApi
} from '../api';

import UserHomepage from '../components/UserHomepage';
import './styles/_user-homepage.scss';

function UserHomepageContainer() {
  return (
    <AuthorisationConsumer>
      {({ selectedOrganisation }) => {
        const { name, organisationId, preferences } = selectedOrganisation;
        const { firstName } = credentials.get().user;
        const { logEventMutation } = requests;

        return (
          <RequestProvider
            requests={{
              getUserRecentActivity: getUserRecentActivityApi(),
              getUserRecentElevates: getUserRecentElevatesApi(),
              getAssetThumbnail: getAssetThumbnailApi(organisationId),
              getBannerArticles: getBannerArticlesApi(),
              logEvent: logEventMutation()
            }}
          >
            {({
              getUserRecentActivity,
              getAssetThumbnail,
              getUserRecentElevates,
              getBannerArticles,
              logEvent
            }) => (
              <GlobalStoreConsumer>
                {(globalStore) => (
                  <div className="user-homepage page">
                    <PageHeader title={`Hey ${firstName}!`} subtitle={name} />
                    <UserHomepage
                      shootstaAI={preferences?.shootstaAI || false}
                      organisation={selectedOrganisation}
                      getUserRecentActivity={getUserRecentActivity}
                      getUserRecentElevates={getUserRecentElevates}
                      getAssetThumbnail={getAssetThumbnail}
                      getBannerArticles={getBannerArticles}
                      globalStore={globalStore}
                      logEvent={logEvent}
                    />
                  </div>
                )}
              </GlobalStoreConsumer>
            )}
          </RequestProvider>
        );
      }}
    </AuthorisationConsumer>
  );
}

export default withRouter(UserHomepageContainer);

import gql from 'graphql-tag';

const getBannerArticlesQuery = gql`
  query getBannerArticles($organisationId: String) {
    getBannerArticles(organisationId: $organisationId) {
      title
      link
      topics
    }
  }
`;

export default () => ({
  query: getBannerArticlesQuery,
  module: 'articles',
  ignoreCache: true,
  buildVariables: (args: any) => args
});

import { gql } from 'apollo-boost';
import config from '../../config';

const LOGIN_QUERY = gql`
  query ($email: String!, $password: String!, $alias: String!) {
    login(email: $email, password: $password, alias: $alias) {
      auth
      token
      user {
        firstName
        lastName
        userId
        email
        imageUrl
        email
        preferences
        isAvailable
        initialSetup
        isAnonymous
      }
    }
  }
`;

export default () => ({
  query: LOGIN_QUERY,
  module: config.HIDE_FEATURES.LOGIN_VIA_IDENTITY ? 'users' : 'identity',
  ignoreCache: true,
  buildVariables: ({
    email,
    password,
    alias
  }: {
    email: string,
    password: string,
    alias: string
  }) => ({ email, password, alias })
});

import { gql } from 'apollo-boost';

const GET_ORG_SSO_CONFIG = gql`
  query ($alias: String!) {
    getOrganisationSSOConfig(alias: $alias) {
      ssoConfig
    }
  }
`;

export default () => ({
  query: GET_ORG_SSO_CONFIG,
  module: 'organisations',
  ignoreCache: true,
  buildVariables: (alias: string) => ({ alias })
});

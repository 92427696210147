import { COLOURS } from '@shootsta/common-react';

export default {
  DRAFT: COLOURS.grey.regent,
  PENDING_MASTER: COLOURS.cyan.jungleGreen.monkey,
  EDITING: '#F69F1F',
  INTERNAL_REVIEW: COLOURS.cyan.jungleGreen.monkey,
  PENDING_REVIEW: '#F69F1F',
  APPROVED: COLOURS.cyan.jungleGreen.monkey,
  COMPLETE: COLOURS.cyan.jungleGreen.monkey,
  ON_HOLD: COLOURS.red.redApple,
  AT_RISK: COLOURS.red.redApple,
  IN_PROGRESS: COLOURS.userComment.unsafeOchre,
  REQUIRES_ACTION: COLOURS.red.redApple
};
